<template>
  <!-- Main content -->
  <section class="content">
    <div class="btn-box">
      <el-form :inline="true">
        <el-form-item label="用户user_id：">
          <el-input v-model="user_id" placeholder="请输入user_id"></el-input>
        </el-form-item>
        <el-form-item label="订单号：">
          <el-input v-model="orderId" placeholder="请输入订单号"></el-input>
        </el-form-item>
        <el-form-item label="商品id：">
          <el-input v-model="goods_id" placeholder="请输入商品id"></el-input>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker
            v-model="start"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="付款开始时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker
            v-model="end"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="付款结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="seach(1)">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table v-loading="loading" :data="pddList" border>
      <el-table-column fixed prop="order_id" label="编号" width="150">
      </el-table-column>
      <el-table-column fixed prop="order_sn" label="订单号" width="150">
      </el-table-column>
      <el-table-column fixed label="用户user_id (user_key)" width="150">
        <template slot-scope="scope">
          {{ scope.row.user_id }}({{ scope.row.user_key }})
        </template>
      </el-table-column>
      <el-table-column fixed prop="item_id" label="商品id" width="140">
      </el-table-column>
      <el-table-column
        prop="item_title"
        label="商品标题"
        width="240"
      ></el-table-column>
      <el-table-column label="商品图片">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.item_pic"
            :preview-src-list="[scope.row.item_pic]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="pay_amount"
        label="支付金额"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="user_commission"
        label="用户佣金"
        width="100"
      ></el-table-column>
      <el-table-column label="支付时间">
        <template slot-scope="scope">
          {{ scope.row.pay_time | dateTime }}
        </template>
      </el-table-column>
      <el-table-column label="订单状态">
        <template slot-scope="scope">
          <span
            :class="
              scope.row.status == 1
                ? 'green'
                : scope.row.status == 3
                ? 'blue'
                : scope.row.status == 4
                ? 'warn'
                : scope.row.status == 5 ||
                  scope.row.status == 6 ||
                  scope.row.status == 7
                ? 'red'
                : ''
            "
          >
            {{ scope.row.status | status }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="是否比价">
        <template slot-scope="scope">
          {{ scope.row.price_compare_status | compare }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="alterDialog(scope.row)"
            v-if="scope.row.status == 1 || scope.row.status == 2"
            >修改</el-button
          >
          <el-button
            type="danger"
            size="mini"
            @click="settlement(scope.row.order_id)"
            v-if="scope.row.status == 1"
            >结算</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total, sizes,prev, pager, next,jumper"
      :total="total"
      @size-change="sizeChange"
      @current-change="currentChange"
    >
    </el-pagination>

    <el-dialog
      title="修改"
      :visible.sync="dialog"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="用户user_id">
          <el-input v-model="unionid" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="佣金">
          <el-input v-model="user_commission" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
      </div>
    </el-dialog>
  </section>
  <!-- /.content -->
</template>

<script>
let common = JSON.parse(window.localStorage.getItem("common"));
import * as api from "@/config/api";
export default {
  name: "pddOrder",
  components: {},

  data() {
    return {
      pddList: [],
      total: 0,
      page: 1,
      pagesize: 10,

      user_id: "",
      goods_id: "",
      orderId: "",
      start: "",
      end: "",
      loading: true,
      dialog: false,
      order_id: "",
      unionid: "",
      user_commission: "",
    };
  },
  watch: {},
  filters: {
    status(val) {
      let name = "";
      if (JSON.stringify(common.order_status_list).indexOf(val) != -1) {
        common.order_status_list.map((a, index) => {
          if (val === a.type) {
            name = a.name;
          }
        });
      }
      return name;
    },
    compare(val) {
      return Number(val) === 1 ? "是" : "否";
    },
    dateTime(val) {
      let day = new Date(Number(val) * 1000);
      if (Number(val) === 0) {
        return "";
      }
      return (
        day.getFullYear() +
        "-" +
        `${
          day.getMonth() + 1 < 10
            ? "0" + (day.getMonth() + 1)
            : day.getMonth() + 1
        }` +
        "-" +
        `${day.getDate() < 10 ? "0" + day.getDate() : day.getDate()}` +
        " " +
        `${day.getHours() < 10 ? "0" + day.getHours() : day.getHours()}` +
        ":" +
        `${day.getMinutes() < 10 ? "0" + day.getMinutes() : day.getMinutes()}` +
        ":" +
        `${day.getSeconds() < 10 ? "0" + day.getSeconds() : day.getSeconds()}`
      );
    },
  },
  mounted() {
    this.getPddOrderList();
  },
  methods: {
    settlement(id) {
      this.$confirm("请确定是否结算该订单", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api.pddOrderSettlement(
            {
              order_id: id,
            },
            (res) => {
              this.$message({
                type: "success",
                message: "结算成功!",
              });
              this.getPddOrderList();
            }
          );
        })
        .catch(() => {});
    },
    alterDialog({ order_id, user_id, user_commission }) {
      this.order_id = order_id;
      this.unionid = user_id;
      this.user_commission = user_commission;
      this.dialog = true;
    },
    alter() {
      api.alterPddOrder(
        {
          order_id: this.order_id,
          user_id: this.unionid,
          user_commission: this.user_commission,
        },
        (res) => {
          console.log(res);
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.dialog = false;
          this.seach(1);
        },
        (err) => {
          console.log(err);
        }
      );
    },
    close() {
      this.show = false;
      this.getPddOrderList();
    },
    getPddOrderList(page, size) {
      this.loading = true;
      api.getPddOrderList(
        {
          user_id: this.user_id,
          goods_id: this.goods_id,
          order_sn: this.orderId,
          order_pay_time_start: this.start,
          order_pay_time_end: this.end,
          page: page ? page : this.page,
          pagesize: size ? size : this.pagesize,
        },
        (res) => {
          page && (this.page = page)
          this.pddList = res.data.list;
          this.total = Number(res.data.count);
          this.loading = false;
        }
      );
    },
    sizeChange(val) {
      this.pagesize = val;
      this.getPddOrderList();
    },
    currentChange(val) {
      this.page = val;
      this.getPddOrderList();
    },
    seach(page, size) {
      this.getPddOrderList(page, size);
    },
  },
};
</script>
<style scoped>
.cell {
  text-align: center;
}

.btn-box {
  text-align: left;
  margin-bottom: 20px;
}

.el-image img {
  height: 40px;
}

.el-image-viewer__canvas img {
  width: auto;
  height: auto;
}

.red {
  color: #f56c6c;
}

.blue {
  color: #409eff;
}

.green {
  color: #67c23a;
}

.warn {
  color: #e6a23c;
}
</style>